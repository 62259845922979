import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { enableDebug } from '@sitecore-jss/sitecore-jss-react';
import AppRoot from './AppRoot';
import GraphQLClientFactory from './lib/GraphQLClientFactory';
import config from './temp/config';
import i18ninit from './i18n';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { getMsalConfiguration } from './config/msalConfig';
import ApolloThemeProvider from 'apollo-react/utils/ApolloThemeProvider';
import { getGlobalContext } from './lib/graphql/global-contextsettings-factory';
import { removeLanguageCode } from './lib/utils/removeLanguageCode';

if (process.env.REACT_APP_DEBUG) {
  enableDebug(process.env.REACT_APP_DEBUG);
}
/* eslint-disable no-underscore-dangle */

let renderFunction = (rootElement, component) => {
  const root = createRoot(rootElement);
  root.render(component);
};

let initLanguage = config.defaultLanguage;

/*
  SSR Data
  If we're running in a server-side rendering scenario,
  the server will provide JSON in the #__JSS_STATE__ element
  for us to acquire the initial state to run with on the client.

  This enables us to skip a network request to load up the layout data.
  We are emitting a quiescent script with JSON so that we can take advantage
  of JSON.parse()'s speed advantage over parsing full JS, and enable
  working without needing `unsafe-inline` in Content Security Policies.

  SSR is initiated from /server/server.js.
*/
let __JSS_STATE__ = null;
const ssrRawJson = document.getElementById('__JSS_STATE__');
if (ssrRawJson) {
  __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
}
if (__JSS_STATE__) {
  // when React initializes from a SSR-based initial state, you need to render with `hydrateRoot` instead of `render`
  renderFunction = hydrateRoot;

  // set i18n language SSR state language instead of static config default language
  initLanguage = __JSS_STATE__.sitecore.context.language;
}

/*
  GraphQL Data
  The Apollo Client needs to be initialized to make GraphQL available to the JSS app.
  Not using GraphQL? Remove this, and the ApolloContext from `AppRoot`.
*/
// Apollo supports SSR of GraphQL queries, so like JSS SSR, it has an object we can pre-hydrate the client cache from
// to avoid needing to re-run GraphQL queries after the SSR page loads
const initialGraphQLState =
  __JSS_STATE__ && __JSS_STATE__.APOLLO_STATE ? __JSS_STATE__.APOLLO_STATE : null;

const graphQLClient = GraphQLClientFactory(config.graphQLEndpoint, false, initialGraphQLState);

/*
  App Rendering
*/
// initialize the dictionary, then render the app
// note: if not making a multlingual app, the dictionary init can be removed.
i18ninit(initLanguage).then(async () => {
  // HTML element to place the app into

  const rootElement = document.getElementById('root');
  const globalContext = await getGlobalContext();
  const pca = new PublicClientApplication(
    getMsalConfiguration(
      globalContext?.pageOne?.results[0]?.fields?.signUpPolicy?.value ||
        process.env.REACT_APP_SSO_SIGNUP_POLICY,
      `${window.location.origin}${removeLanguageCode(globalContext?.pageOne?.results[0]?.redirectUri?.jsonValue?.value?.href)}` ||
        process.env.REACT_APP_SSO_REDIRECT_URI,
      `${window.location.origin}${removeLanguageCode(globalContext?.pageOne?.results[0]?.logoutUri?.jsonValue?.value?.href)}` ||
        process.env.REACT_APP_SSO_LOGOUT_REDIRECT_URI
    )
  );
  pca.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      pca.setActiveAccount(event.payload.account);
    }
  });

  renderFunction(
    rootElement,
    <MsalProvider instance={pca}>
      <ApolloThemeProvider>
        <AppRoot
          path={window.location.pathname}
          Router={BrowserRouter}
          graphQLClient={graphQLClient}
          ssrState={__JSS_STATE__}
        />
      </ApolloThemeProvider>
    </MsalProvider>
  );
});
