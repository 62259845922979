import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const initialState = {
  status: '',
  user: { id: '9', name: 'Test Users', userImage: '', userAddress: '', isLogin: false },
};

export const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    login: (state, action) => {
      console.log('action---', action);
      console.log('state---', state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userDetails.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = 'fulfilled';
      })
      .addCase(userDetails.rejected, (state) => {
        state.status = 'error';
      });
  },
});

export const userDetails = createAsyncThunk('fetch/userDetails', async () => {
  // const data = await axiosInstance.get('products').then((res) => res.data);
  // return data;
});

export const { login } = authSlice.actions;
export default authSlice.reducer;
