import * as React from 'react';
import Grid from 'apollo-react/components/Grid';
import IDSFooter from 'apollo-react/components/Footer';
import './Footer.css';
const WHITE_BG = '1';

const Footer = (route) => {
  const { fields } = route;
  const languageSelector = fields?.selectedLanguages.map((language) => ({
    text: language.fields?.displayName?.value,
    value: language.fields?.iso?.value,
  }));

  const linkBtns = fields?.footerlinks.map((link) => ({
    label: link.fields?.link?.value?.text,
    href: link.fields?.link?.value?.href,
    target: link.fields?.link?.value?.target,
  }));

  const isWhiteBG = route?.params?.isWhiteBG === WHITE_BG;

  return (
    <Grid
      container
      direction={'column'}
      pt={{ sm: '175px', md: '10px' }}
      sx={{
        width: '100%',
        backgroundColor: isWhiteBG ? '#F6F7FB' : null,
      }}
    >
      <Grid item sx={{ fontSize: '2rem' }}>
        <IDSFooter
          className="PhFooter"
          buttonProps={linkBtns}
          languagePickerProps={{
            noDeselect: true,
            defaultValue: languageSelector[0]?.value,
            placeholder: 'Language',
            onChange: (value) => console.log(`${value} was selected`),
            menuItems: languageSelector,
          }}
          darkMode={!isWhiteBG}
          style={{ backgroundColor: isWhiteBG ? '#F6F7FB' : 'unset' }}
        />
      </Grid>
    </Grid>
  );
};

export default Footer;
