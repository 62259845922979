export const getMsalConfiguration = (policy, redirectUrl, logoutUrl) => {
  const msalConfiguration = {
    auth: {
      clientId: process.env.REACT_APP_SSO_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
      authority: `${process.env.REACT_APP_SSO_DOMAIN}/${process.env.REACT_APP_SSO_TENANT}/${policy}`, // Choose SUSI as your default authority.
      knownAuthorities: [`${process.env.REACT_APP_SSO_DOMAIN}`], // Mark your B2C tenant's domain as trusted.
      redirectUri: redirectUrl,
      navigateToLoginRequestUrl: false, // If 'true', will navigate back to the original request location before processing the auth code response.
      postLogoutRedirectUri: logoutUrl,
    },
    cache: {
      cacheLocation: process.env.REACT_APP_SSO_CACHE_LOCATION, // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO between tabs.
      storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
    },
  };
  return msalConfiguration;
};
