import graphQLClient from '../../graphql/graphQLClient';
import {
  siteSettingsQuery,
  siteSettingsQueryParam,
} from '../../graphql/queries/getSiteSettingsQuery';

export const getGlobalContext = async () => {
  const globalContext = await graphQLClient.request(siteSettingsQuery, siteSettingsQueryParam);
  console.log('globalContext', globalContext);
  return globalContext;
};
