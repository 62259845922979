import React from 'react';
import LoginLayout from './Layouts/LoginLayout/Login';
import DefaultLayout from './Layouts/DefaultLayout/DefaultLayout';

const Layout = ({ route, ...rest }) => {
  /**
   * TODO
   * have people from sitecore include the template name here instead of relying
   * in the route URL
   */
  switch (route?.fields?.layoutType?.value) {
    case 'Login':
      return <LoginLayout route={route} {...rest} />;
    default:
      return <DefaultLayout route={route} {...rest} />;
  }
};

export default Layout;
