import React from 'react';
import Typography from 'apollo-react/components/Typography';
import Card from 'apollo-react/components/Card';
import CardContent from 'apollo-react/components/CardContent';
import Container from 'apollo-react/components/Container';
import idsTokens from 'apollo-react/utils/tokens/idsTokens';
import Avatar from 'apollo-react/components/Avatar';
import Divider from 'apollo-react/components/Divider';
import TextField from 'apollo-react/components/TextField';
import Grid from 'apollo-react/components/Grid';
import DatePicker from 'apollo-react/components/DatePicker';
import Autocomplete from 'apollo-react/components/Autocomplete';
import Checkbox from 'apollo-react/components/Checkbox';
import CheckboxGroup from 'apollo-react/components/CheckboxGroup';
import Button from 'apollo-react/components/Button';
import Box from 'apollo-react/components/Box';
import { FormLoginRequestObject } from '../../lib/utils/formLoginRequestObject';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { login } from '../../services/Authentication/SsoAuth';
import './Registration.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import parse from 'html-react-parser';

export default function PhRegistration({ fields }) {
  const [contactValue, setContactValue] = React.useState([]);
  const fileUploadRef = React.useRef(null);
  const [userImage, setUserImage] = React.useState(null);
  const { sitecoreContext } = useSitecoreContext();
  const { instance } = useMsal() || {};
  const [studyConsent, setStudyConsent] = React.useState('');
  const [clinicalConsent, setClinicalConsent] = React.useState('');
  const [states, setStates] = React.useState([]);
  const [seletedState, setSelectedState] = React.useState(null);

  const handleChange1 = (e) => {
    setContactValue(e.target.value);
  };

  // const handleImageUpload = (event) => {
  //   event.preventDefault();
  //   fileUploadRef.current.click();
  // };

  // const removeSelectedImage = () => {
  //   setUserImage(null);
  // };

  const ssoLogin = async () => {
    try {
      const loginRequest = await FormLoginRequestObject({}, sitecoreContext);
      await login(loginRequest, instance);
    } catch (error) {
      console.error(error);
    }
  };

  const uploadImageDisplay = async () => {
    const uploadedFile = fileUploadRef.current.files[0];
    const cachedURL = URL.createObjectURL(uploadedFile);
    setUserImage(cachedURL);
  };

  const _generalInformation = () => {
    const birthListSelector =
      fields?.generalInformation?.fields?.formFieldsList[3]?.fields?.gender.map((gender) => ({
        label: gender.fields?.label?.value,
        id: gender.fields?.id?.value,
      }));
    return (
      <>
        <Typography variant="subtitle" emphasis="high">
          {fields?.generalInformation?.fields?.sectionTitle?.value}
        </Typography>

        <Grid container spacing={2} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
          <Grid item xs={12} md={5}>
            <TextField
              style={{ width: '100%' }}
              label={fields?.generalInformation?.fields?.formFieldsList[0]?.fields?.label?.value}
              placeholder=""
              // error={true}
              // helperText="Name is missing"
              required={
                fields?.generalInformation?.fields?.formFieldsList[0]?.fields?.enableRequiredField
                  ?.value
              }
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              style={{ width: '100%' }}
              label={fields?.generalInformation?.fields?.formFieldsList[1]?.fields?.label?.value}
              // label="Middle initial(optional)"
              placeholder=""
              helperText=""
              required={
                fields?.generalInformation?.fields?.formFieldsList[1]?.fields?.enableRequiredField
                  ?.value
              }
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              style={{ width: '100%' }}
              label={fields?.generalInformation?.fields?.formFieldsList[2]?.fields?.label?.value}
              placeholder=""
              helperText=""
              required={
                fields?.generalInformation?.fields?.formFieldsList[2]?.fields?.enableRequiredField
                  ?.value
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              label={fields?.generalInformation?.fields?.formFieldsList[3]?.fields?.label?.value}
              source={birthListSelector}
              fullWidth
              popupIcon={<ArrowDropDownIcon fontSize={'large'} />}
              onChange={() => {}}
              size="medium"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker
              style={{ width: '100%' }}
              fullWidth={true}
              label={fields?.generalInformation?.fields?.formFieldsList[4]?.fields?.label?.value}
              size="medium"
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const _updateState = (states) => {
    if (states) {
      const formatedState = states.map((state) => ({
        label: state.fields?.label?.value,
        value: state.fields?.id?.value,
        key: state.fields?.id?.key,
      }));
      setStates(formatedState);
    } else {
      setStates([]);
    }
    setSelectedState(null);
  };

  const _contactInformation = () => {
    const phoneTypes =
      fields?.contactInformation?.fields?.phoneFieldList[1]?.fields?.phoneTypes.map(
        (phoneType) => ({
          label: phoneType.fields?.label?.value,
          value: phoneType.fields?.id?.value,
        })
      );

    const countryLists =
      fields?.contactInformation?.fields?.addressFieldList[4]?.fields?.countries.map((country) => ({
        label: country.fields?.label?.value,
        value: country.fields?.id?.value,
        studyConsent: country?.fields?.studyConsent?.value,
        clinicalConsent: country?.fields?.clinicalConsent?.value,
        states: country?.fields?.stateList,
      }));

    return (
      <>
        <Typography variant="subtitle" emphasis="high">
          {fields?.contactInformation?.fields?.sectionTitle?.value}
        </Typography>
        <Typography variant="bodyDefault">
          {fields?.contactInformation?.fields?.sectionDescription?.value}
        </Typography>
        <Typography variant="subtitle" emphasis="high">
          {fields?.contactInformation?.fields?.subTitle1?.value}
        </Typography>
        {/* Phone Number */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              style={{ width: '100%' }}
              label={fields?.contactInformation?.fields?.phoneFieldList[0]?.fields?.label?.value}
              placeholder=""
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              label={fields?.contactInformation?.fields?.phoneFieldList[1]?.fields?.label?.value}
              source={phoneTypes}
              fullWidth
              onChange={() => {}}
              size="medium"
            />
          </Grid>
        </Grid>
        <Typography variant="subtitle" emphasis="high">
          {fields?.contactInformation?.fields?.subTitle2?.value}
        </Typography>
        {/* Street Address */}

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              style={{ width: '100%' }}
              label={fields?.contactInformation?.fields?.addressFieldList[0]?.fields?.label?.value}
              helperText=""
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              style={{ width: '100%' }}
              label={fields?.contactInformation?.fields?.addressFieldList[1]?.fields?.label?.value}
              helperText=""
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              style={{ width: '100%' }}
              label={fields?.contactInformation?.fields?.addressFieldList[2]?.fields?.label?.value}
              helperText=""
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              label={fields?.contactInformation?.fields?.addressFieldList[3]?.fields?.label?.value}
              source={states}
              value={seletedState}
              fullWidth
              onChange={(event, value) => {
                setSelectedState(value);
              }}
              helperText=""
              size="medium"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              label={fields?.contactInformation?.fields?.addressFieldList[4]?.fields?.label?.value}
              source={countryLists}
              fullWidth
              onChange={(event, value) => {
                setStudyConsent(value?.studyConsent);
                setClinicalConsent(value?.clinicalConsent);
                _updateState(value?.states);
              }}
              helperText={''}
              size="medium"
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              style={{ width: '100%' }}
              label={fields?.contactInformation?.fields?.addressFieldList[5]?.fields?.label?.value}
              helperText=""
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const _languageOption = () => {
    const primaryLanguageList =
      fields?.languageOptions?.fields?.formFieldsList[0]?.fields?.languageList.map(
        (primaryLanguage) => ({
          label: primaryLanguage.fields?.displayName?.value,
          value: primaryLanguage.fields?.iso?.value,
        })
      );

    const secondaryLanguageList =
      fields?.languageOptions?.fields?.formFieldsList[1]?.fields?.languageList.map(
        (primaryLanguage) => ({
          label: primaryLanguage.fields?.displayName?.value,
          value: primaryLanguage.fields?.iso?.value,
        })
      );

    return (
      <>
        <Typography variant="subtitle" emphasis="high">
          {fields?.languageOptions?.fields?.sectionTitle?.value}
        </Typography>
        <Typography variant="bodyDefault">
          {fields?.languageOptions?.fields?.sectionDescription?.value}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              label={fields?.languageOptions?.fields?.formFieldsList[0]?.fields?.label?.value}
              source={primaryLanguageList}
              fullWidth
              onChange={() => {}}
              size="medium"
              required
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ marginTop: { xs: '2px', md: '0px' } }}>
            <Autocomplete
              label={fields?.languageOptions?.fields?.formFieldsList[1]?.fields?.label?.value}
              source={secondaryLanguageList}
              fullWidth
              value={''}
              onChange={() => {}}
              size="medium"
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const _communicationPreferences = () => {
    const programNotificationPhoneList =
      fields?.communicationPreferences?.fields?.consentList[0]?.fields?.consentMediumList;
    const clinicalPhoneList =
      fields?.communicationPreferences?.fields?.consentList[1]?.fields?.consentMediumList;
    const acknowledgeText = fields?.communicationPreferences?.fields?.acknowledgeText;

    return (
      <>
        {/* Study/Program Notifications */}
        <Typography variant="subtitle" emphasis="high">
          {fields?.communicationPreferences?.fields?.title?.value}
        </Typography>
        <Typography variant="bodyDefault" emphasis="high">
          {fields?.communicationPreferences?.fields?.consentList[0]?.fields?.title?.value}
        </Typography>
        <Typography variant="bodyDefault">{studyConsent && parse(studyConsent)}</Typography>

        <Grid>
          <Card tabIndex={0}>
            <CardContent style={{ gap: '0px', paddingTop: '20px' }}>
              <Typography variant="bodySmall">
                {fields?.communicationPreferences?.fields?.consentList[0]?.fields?.subTitle?.value}
              </Typography>
              <CheckboxGroup value={contactValue} onChange={handleChange1}>
                {programNotificationPhoneList.map((phone) => {
                  const { name, displayName, fields } = phone;
                  return <Checkbox key={name} value={fields?.id?.value} label={displayName} />;
                })}
              </CheckboxGroup>
            </CardContent>
          </Card>
          <Typography variant="bodySmall" style={{ fontStyle: 'italic' }}>
            {
              fields?.communicationPreferences?.fields?.consentList[0]?.fields?.disclaimerText
                ?.value
            }
          </Typography>
        </Grid>
        {/* Clinical Opportunities */}
        <Typography variant="bodyDefault" emphasis="high">
          {fields?.communicationPreferences?.fields?.consentList[1]?.fields?.title?.value}
        </Typography>
        <Typography variant="bodyDefault">
          {clinicalConsent ? parse(clinicalConsent) : ''}
        </Typography>
        <Grid>
          <Card tabIndex={0}>
            <CardContent style={{ gap: '0px', paddingTop: '20px' }}>
              <Typography variant="bodySmall">
                {fields?.communicationPreferences?.fields?.consentList[1]?.fields?.subTitle?.value}
              </Typography>
              <CheckboxGroup value={contactValue} onChange={handleChange1}>
                {clinicalPhoneList.map((phone) => {
                  const { name, displayName, fields } = phone;
                  return <Checkbox key={name} value={fields?.id?.value} label={displayName} />;
                })}
              </CheckboxGroup>
            </CardContent>
          </Card>
          <Typography variant="bodySmall" style={{ fontStyle: 'italic' }}>
            {
              fields?.communicationPreferences?.fields?.consentList[1]?.fields?.disclaimerText
                ?.value
            }
          </Typography>
        </Grid>
        <Box>
          {/* <RichText field={acknowledgeText?.value}/> */}
          <CheckboxGroup
            label={fields?.communicationPreferences?.fields?.requiredText?.value}
            value={contactValue}
            onChange={handleChange1}
            required
          >
            <Checkbox
              sx={{
                alignItems: 'center',
              }}
              value=""
              label={<Typography>{parse(acknowledgeText?.value)}</Typography>}
            />
          </CheckboxGroup>
        </Box>
        <Grid style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Button
            variant="primary"
            onClick={(event) => {
              event.preventDefault();
              ssoLogin();
            }}
          >
            {fields?.continueButton?.value?.text}
          </Button>
        </Grid>
      </>
    );
  };

  const _profileInformation = () => {
    return (
      <>
        <CardContent style={{ gap: 16 }}>
          <Typography variant="title" emphasis="high">
            {fields?.profileTtitle?.value}
          </Typography>
        </CardContent>
        <CardContent>
          <Grid container sx={() => ({})}>
            <Grid
              item
              xs={12}
              sx={{
                flexDirection: { xs: 'column', md: 'row' },
                display: 'flex',
                gap: '24px',
              }}
            >
              <Avatar src={userImage} disableShadow color="light" size="large">
                MA
              </Avatar>
              <Box>
                <Box
                  sx={() => ({
                    paddingLeft: 'unset',
                    flexDirection: 'row',
                    display: 'flex',
                  })}
                >
                  <Typography variant="body1">{fields?.userNameLabel?.value + ':'}</Typography>
                  <Typography paddingLeft={'5px'} variant="bodyDefault">
                    {'meta@iqvia.com'}
                  </Typography>
                </Box>
                <Box style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                  <input
                    type="file"
                    id="file"
                    ref={fileUploadRef}
                    onChange={uploadImageDisplay}
                    hidden
                  />
                  <Button
                    // onClick={handleImageUpload}
                    variant="text"
                    style={{ marginRight: 10 }}
                  >
                    {fields?.addPhotoText?.value}
                  </Button>

                  <div
                    style={{
                      height: 15,
                      marginRight: '10px',
                    }}
                  >
                    <Divider type="dark" orientation="vertical" />
                  </div>
                  <Button
                    // onClick={removeSelectedImage}
                    disabled={isDisableRemoveImage}
                    variant="text"
                    style={{ marginRight: 10 }}
                  >
                    {fields?.removePhotoText?.value}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </>
    );
  };

  const isDisableRemoveImage = userImage ? false : true;

  return (
    <UnauthenticatedTemplate>
      <Container
        className="PhRegistration"
        disableGutters
        maxWidth={false}
        sx={{
          width: '85%',
        }}
      >
        <Typography paddingTop={'10px'} variant="subheadline" emphasis="high">
          {fields?.regTitle?.value}
        </Typography>
        <Typography variant="title">{fields?.regDescription?.value}</Typography>
        <Card
          sx={{
            marginTop: '24px',
            padding: idsTokens.functional.spacing.container.spacious,
          }}
        >
          {_profileInformation()}
          <CardContent style={{ gap: '16px' }}>{_generalInformation()}</CardContent>
          <CardContent style={{ gap: '12px' }}>{_contactInformation()}</CardContent>
          <CardContent style={{ gap: '16px' }}>{_languageOption()}</CardContent>
          <CardContent style={{ gap: '8px' }}>{_communicationPreferences()}</CardContent>
        </Card>
      </Container>
    </UnauthenticatedTemplate>
  );
}
