// import { axiosInstance } from '../api';

export const fetchUserEmail = async (userName) => {
  try {
    // This will be uncommented once apis will be created in future
    // const response = await axiosInstance.get('/api/identity/getEmailIdFromUserName', {
    //   userName: userName,
    // });
    // const data = await response?.json();
    // return data?.email;
    return userName;
  } catch (error) {
    console.error(error);
    return '';
  }
};
