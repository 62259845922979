export const FormLoginRequestObject = async (data, sitecoreContext) => {
  try {
    const loginRequest = {
      loginHint: data?.email || '',
      extraQueryParameters: {
        firstname:
          sitecoreContext?.siteSettings?.isFirstNamePrepopulate?.value === '1'
            ? data?.firstName
            : '',
        lastname:
          sitecoreContext?.siteSettings?.isLastNamePrepopulate?.value === '1' ? data?.lastName : '',
        ui_locales: sitecoreContext?.language || 'en',
        scope: 'openid',
        spselection:
          sitecoreContext?.siteSettings?.isSocialIdpEnabled?.value === '1'
            ? sitecoreContext?.siteSettings?.socialIdpQueryParamValue?.value || 'sp'
            : '',
      },
    };
    return loginRequest;
  } catch (error) {
    console.error(error);
  }
};
