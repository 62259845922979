import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../services/api';

export const initialState = {
  status: '',
  profileData: {},
};

const profileDetailsPageSlice = createSlice({
  name: 'profileDetailsPageData',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileDetailsData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProfileDetailsData.fulfilled, (state, action) => {
        state.profileData = action.payload;
        state.status = 'fulfilled';
      })
      .addCase(fetchProfileDetailsData.rejected, (state) => {
        state.status = 'error';
      });
  },
});

export const fetchProfileDetailsData = createAsyncThunk(
  'fetch/profileDetailsPageData',
  async () => {
    const data = await axiosInstance
      .post('/User/GetUser?email=example%40example.com')
      .then((res) => res.data);
    return data;
  }
);

export default profileDetailsPageSlice.reducer;
