import React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import Card from 'apollo-react/components/Card';
import CardContent from 'apollo-react/components/CardContent';
import Container from 'apollo-react/components/Container';
import idsTokens from 'apollo-react/utils/tokens/idsTokens';
import TextField from 'apollo-react/components/TextField';
import Button from 'apollo-react/components/Button';
import CardActions from 'apollo-react/components/CardActions';
import { login } from '../../services/Authentication/SsoAuth';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { FormLoginRequestObject } from '../../lib/utils/formLoginRequestObject';
import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { fetchUserEmail } from '../../services/User/userIdentityService';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../redux/slices/loaderSlice';

const PreLoginTile = ({ fields }) => {
  const [error, setError] = React.useState(false);
  const [validEmailError, setValidEmailError] = React.useState(false);
  const [emailExistsError, setEmailExistsError] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const { sitecoreContext } = useSitecoreContext();
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const emailRegexPattern = fields?.regexForFormatCheck?.value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const emailRegex = new RegExp(emailRegexPattern);
  const ssoLogin = async (userName) => {
    try {
      dispatch(showLoader());
      if (emailRegex && emailRegex.test(username)) {
        setValidEmailError(false);
        const emailAddress = await fetchUserEmail(userName);
        if (emailAddress && emailAddress?.trim() !== '') {
          const loginRequest = await FormLoginRequestObject(
            { email: emailAddress },
            sitecoreContext
          );
          await login(loginRequest, instance);
        } else {
          setEmailExistsError(true);
        }
      } else {
        setValidEmailError(true);
      }

      dispatch(hideLoader());
    } catch (error) {
      console.error(error);
      dispatch(hideLoader());
    }
  };
  return (
    <UnauthenticatedTemplate>
      <Container disableGutters sx={{ paddingRight: 0 }}>
        <Grid container className={'PreLogin'} sx={{ width: '100%', paddingLeft: 0 }} pt={'72px'}>
          <Grid
            item
            md={5}
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          ></Grid>
          <Grid
            item
            xs={12}
            md={7}
            pr={{ xs: '24px', sm: '72px', md: '48px' }}
            pl={{ xs: '24px', sm: '72px', md: '24px' }}
            sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          >
            <Container
              disableGutters
              sx={{
                maxWidth: {
                  xs: '313px',
                  sm: '552px',
                  md: '467px',
                  lg: '600px',
                },
              }}
            >
              <Image
                field={fields?.logoImage}
                style={{
                  marginBottom: idsTokens.functional.spacing.content.spacious,
                }}
              />
              <Typography
                variant="subheadline"
                emphasis="high"
                paddingTop={'20px'}
                darkMode
                sx={{
                  textAlign: 'left',
                  paddingTop: idsTokens.functional.spacing.content.comfortable,
                  paddingBottom: idsTokens.functional.spacing.content.comfortable,
                  fontSize: { sm: '48px !important', md: '32px !important' },
                }}
              >
                {fields?.heading?.value}
              </Typography>
              <Typography
                variant="subtitle"
                darkMode
                sx={{
                  textAlign: 'left',
                  fontSize: { sm: '24px !important', md: '20px !important' },
                }}
              >
                {fields?.subheading?.value}
              </Typography>
              <Card
                sx={{
                  marginTop: '24px',
                  padding: idsTokens.functional.spacing.container.spacious,
                }}
              >
                <CardContent
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    paddingLeft: 'unset',
                    paddingRight: 'unset',
                  }}
                >
                  <Typography variant="title" emphasis="high">
                    {fields?.cardtitle?.value}
                  </Typography>
                  <TextField
                    label={fields?.textboxTitle?.value}
                    placeholder={fields?.placeholderText?.value}
                    error={error || emailExistsError || validEmailError}
                    fullWidth
                    helperText={
                      error
                        ? fields?.requiredErrorMsg?.value || 'Email is required'
                        : emailExistsError
                          ? fields?.emailExistsErrorMsg?.value ||
                            'oops !! looks like your email address doesnt exist'
                          : fields?.enableFormatCheck?.value && validEmailError
                            ? fields?.formatErrorMsg?.value || 'Invalid Email format'
                            : ''
                    }
                    onChange={(event) => {
                      setUsername(event.target.value);
                      if (username?.trim()?.length > 0 || username.trim() !== '') {
                        setError(false);
                        setValidEmailError(false);
                      }
                    }}
                  />
                </CardContent>
                <CardActions
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 'unset',
                    padding: 'unset !important',
                  }}
                >
                  <Button
                    variant="primary"
                    href={fields?.nextBtn?.value?.href}
                    fullWidth
                    onClick={(event) => {
                      if (username?.length === 0 || username === ' ') {
                        event.preventDefault();
                        setError(true);
                      } else {
                        setError(false);
                        ssoLogin(username);
                      }
                    }}
                  >
                    {fields?.nextBtn?.value?.text}
                  </Button>
                  <Button variant="text" href={fields?.forgotUsernameBtn?.value?.href} fullWidth>
                    {fields?.forgotUsernameBtn?.value?.text}
                  </Button>
                </CardActions>
              </Card>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </UnauthenticatedTemplate>
  );
};
export default PreLoginTile;
