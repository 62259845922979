import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSsoData } from '../../redux/slices/UserSsoDetails';
import { useMsal } from '@azure/msal-react';

const useSsoAuthentication = (route) => {
  const dispatch = useDispatch();
  const isLandingPage = route?.fields?.isLandingPage?.value;
  const { instance } = useMsal();
  const currentAccount = instance?.getActiveAccount();

  useEffect(() => {
    if (isLandingPage) {
      if (currentAccount && currentAccount?.idTokenClaims) {
        const data = {
          firstName: currentAccount?.idTokenClaims?.given_name ?? '',
          lastName: currentAccount?.idTokenClaims?.family_name ?? '',
          email: currentAccount?.idTokenClaims?.email ?? '',
          phoneNumber: '',
          country: '',
        };
        dispatch(setSsoData(data));
      }
    }
  }, [currentAccount, currentAccount?.idTokenClaims]);
};

export default useSsoAuthentication;
