import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import fastDeepEqual from 'fast-deep-equal/es6/react';
import Helmet from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.css';
import '../../assets/app.css';
import useSsoAuthentication from '../../lib/hooks/useSsoAuthentication';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import useAuthIdleTimer from '../../lib/hooks/useIdleTimer';
import Box from 'apollo-react/components/Box';

const DefaultLayout = ({ route }) => {
  const [isClient, setIsClient] = useState(false);
  useSsoAuthentication(route);
  useAuthIdleTimer();
  const Loader = lazy(() => import('../../components/shared/Loader/Loader'));
  const isAuthenticated = useIsAuthenticated();
  const sitecoreContext = useSitecoreContext();
  const navigate = useNavigate();
  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (isAuthenticated && !route?.fields?.isLandingPage?.value) {
      navigate(sitecoreContext?.sitecoreContext?.siteSettings?.redirectUri?.jsonValue?.value?.href);
    }
  }, []);

  return (
    <Box paddingTop="56px">
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <title>
          {(route.fields && route.fields.pageTitle && route.fields.pageTitle.value) || 'Page'}
        </title>
      </Helmet>
      {isClient && (
        <Suspense fallback={<div>Loading...</div>}>
          <Loader />
        </Suspense>
      )}
      <div>
        <Placeholder name="jss-header" rendering={route} />
      </div>
      <div className="defaultContainer">
        <Placeholder name="jss-main" rendering={route} />
      </div>
      <div>
        <Placeholder name="jss-footer" rendering={route} />
      </div>
    </Box>
  );
};

const propsAreEqual = (prevProps, nextProps) => {
  if (fastDeepEqual(prevProps.route, nextProps.route)) return true;

  return false;
};

export default React.memo(DefaultLayout, propsAreEqual);
