import React from 'react';
import { neutral6 } from 'apollo-react/colors';
import Avatar from 'apollo-react/components/Avatar';
import Box from 'apollo-react/components/Box';
import NavigationBar from 'apollo-react/components/NavigationBar';
import Typography from 'apollo-react/components/Typography';
import idsTokens from 'apollo-react/utils/tokens/idsTokens';

const navClick = (elem) => {
  console.log(`${elem.text} clicked`, elem);
  /**
   * Here yoou can perform the action associated to the item that was click
   */
};

const PhNavigation = (routes) => {
  const { fields, params } = routes;
  const { isShortNav } = params;
  const profileMenuProps = {
    title: fields.profileTitle.value,
    logoutText: fields.logoutBtn.value.text,
    icon: <Avatar src="./avatar_eg.png" size="small" />,
    extraContent: (
      <Typography
        style={{
          color: neutral6,
          textAlign: 'center',
          fontSize: 13,
          lineHeight: '24px',
          marginBottom: 8,
        }}
      >
        {fields.versionNumber.value}
      </Typography>
    ),
    menuItems: fields.profileCTALinks.map((item) => ({
      ...item,
      text: item.displayName,
      pathname: item.url,
      // icon: <img {...item.fields.linkIcon.value} />,
      icon: (props) => <img {...props} {...item.fields.linkIcon.value} />,
    })),
  };

  // console.log('PhNavigation', { fields, profileMenuProps });

  return (
    <NavigationBar
      LogoComponent={() => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: idsTokens.navbar.brand.spacing.appName.gap,
          }}
        >
          <Box
            component="img"
            src={fields.headerLogo.value.src}
            alt={fields.headerLogo.value.alt}
            sx={{ height: '30px' }}
          />
          <Typography
            sx={{
              color: 'white',
              lineHeight: '56px',
              marginRight: '24px',
              cursor: 'pointer',
              zIndex: 2,
              whiteSpace: 'nowrap',
            }}
          >
            {fields.headerTitle.value}
          </Typography>
        </Box>
      )}
      profileMenuProps={isShortNav !== '1' && profileMenuProps}
      onClick={navClick}
      // maxWidth={1600}
      waves
      variant="brand"
      sx={{
        '& .MuiToolbar-root > div:first-of-type': {
          padding: 0,
        },
      }}
    />
  );
};

export default PhNavigation;
