import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  isShowDialog: false,
  message: '',
  title: '',
};
export const errorSlice = createSlice({
  name: 'error',
  initialState: initialState,
  reducers: {
    showError(state, action) {
      const { title, message } = action.payload;
      state.isShowDialog = true;
      state.message = message;
      state.title = title;
    },
    clearError(state) {
      state.isShowDialog = false;
      state.message = '';
      state.title = '';
    },
  },
});

export default errorSlice.reducer;
export const errorActions = errorSlice.actions;
