import React from 'react';
import Button from 'apollo-react/components/Button';
import { RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import Typography from 'apollo-react/components/Typography';
import { UnauthenticatedTemplate } from '@azure/msal-react';
import { Grid } from '@mui/material';
import Box from 'apollo-react/components/Box';
import Card from 'apollo-react/components/Card';
import CardContent from 'apollo-react/components/CardContent';
import './SSOModal.css';

export default function SSOModal({ fields }) {
  return (
    <div>
      <UnauthenticatedTemplate>
        <Box
          className="ssomodal"
          sx={(theme) => ({
            background: '#6b6d6e',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
              paddingY: '20px',
            },
          })}
        >
          <Card
            variant="elevation"
            style={{ border: 'none', borderRadius: '10px', overflow: 'hidden' }} // TODO:: After Adding theme need to test without this style
            sx={{
              maxWidth: {
                xs: '90%',
                sm: 594,
              },
            }}
          >
            <Box
              style={{
                background: 'linear-gradient(to bottom, #01828E, #01678E )',
                height: '10px',
              }}
            />
            <CardContent style={{ padding: '24px' }}>
              <Grid
                container
                style={{ alignItems: 'center', paddingBottom: '16px', justifyContent: 'center' }}
              >
                <Image field={fields?.image} height="auto" width="80%" />
              </Grid>
              <Grid>
                <Typography
                  variant="bodyDefault"
                  gutterBottom
                  emphasis="high"
                  style={{ fontSize: '20px' }}
                >
                  {fields?.title.value}
                </Typography>
                <Typography component="span">
                  <RichText field={fields?.description} />
                </Typography>
              </Grid>
              <Grid
                container
                spacing={4}
                sx={(theme) => ({
                  paddingTop: '10px',
                  [theme.breakpoints.up('sm')]: {
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  },
                })}
              >
                {fields?.buttonList?.map((buttonItem, index) => (
                  <Grid key={`${index}`} item sx={{ width: { xs: '100%', sm: 'auto' } }}>
                    <Button
                      fullWidth
                      variant={buttonItem.fields.buttonVariant.fields.name.value}
                      style={{ marginRight: 10 }}
                      href={buttonItem.fields.btnLink.value.href}
                      disabled={buttonItem.fields.buttonVariant.fields.name.value === 'secondary'}
                    >
                      {buttonItem.fields.btnLink.value.text}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </UnauthenticatedTemplate>
    </div>
  );
}
