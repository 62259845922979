import React from 'react';
import { RichText, Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';

const LinkImageText = ({ fields }) => {
  return (
    <Box>
      <Grid container spacing={2} paddingTop={'40px'} flex={'column'}>
        <Grid item>
          <Typography
            variant="h3"
            color={'#0041C2'}
            paddingTop={'10px'}
            sx={{
              textAlign: 'left',
              fontSize: '30px',
              fontStyle: 'normal',
              lineHeight: '32px',
            }}
          >
            <Text field={fields?.title} />
          </Typography>
        </Grid>
        <Grid
          item
          paddingTop={'8px'}
          borderColor={'#0000FF'}
          sx={{
            textAlign: 'left',
            fontSize: '20px',
            fontStyle: 'normal',
            lineHeight: '32px',
          }}
        >
          <RichText field={fields?.description} />
        </Grid>
      </Grid>
      <Grid container spacing={2} paddingTop={'40px'} flex={'row'}>
        {fields?.contentList?.map((contentItem) => (
          <Grid item xs={6} key={contentItem?.id}>
            <Grid item>
              <Image field={contentItem?.fields?.image} width="100%" height="400px" />
            </Grid>
            <Stack paddingTop={'8px'} spacing={2} direction="column">
              <Button
                variant="contained"
                href={contentItem?.fields?.link?.value.href}
                sx={{ backgroundColor: '#343a40' }}
              >
                {contentItem?.fields?.link?.value.text}
              </Button>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default LinkImageText;
