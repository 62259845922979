export const login = async (loginRequest, instance) => {
  try {
    await instance.loginRedirect(loginRequest);
  } catch (error) {
    console.error(error);
  }
};

export const logout = async (instance) => {
  try {
    await instance.logoutRedirect();
  } catch (error) {
    console.error(error);
  }
};
