import React, { useState, useEffect } from 'react';
import '../../assets/ErrorBanner.css'; // Import your CSS file for styling
import { useDispatch } from 'react-redux';
import { errorActions } from '../../redux/slices/error';

function ErrorBanner({ errorMessage }) {
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (errorMessage) {
      setIsVisible(true);
      // Optional: Set a timeout to hide the banner after some time
      const timeoutId = setTimeout(() => {
        setIsVisible(false);
        dispatch(errorActions.clearError());
      }, 5000); // Hide after 5 seconds
      return () => clearTimeout(timeoutId); // Cleanup function to clear timeout on unmount
    }
  }, [errorMessage]); // Re-run effect only when errorMessage changes

  const handleClose = () => {
    setIsVisible(false);
    dispatch(errorActions.clearError());
    // useDispatch()
  };

  return (
    <div className={`api-error-banner ${isVisible ? 'visible' : ''}`}>
      <p>API Error: {errorMessage}</p>
      <button onClick={handleClose}>Close</button>
    </div>
  );
}

export default ErrorBanner;
