import React from 'react';
import { useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import './Loader.css';

const Loader = () => {
  const isLoading = useSelector((state) => state?.loaderSlice?.isLoading);
  // const [open, setOpen] = useState(false);
  // useEffect(() => {
  //   setOpen(isLoading);
  // }, [isLoading]);
  return (
    isLoading && (
      <div className="loader-overlay">
        <div className="loader">
          <Backdrop open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      </div>
    )
  );
};

export default Loader;
