import React from 'react';
import Grid from 'apollo-react/components/Grid';
import Container from 'apollo-react/components/Container';
import Hero from 'apollo-react/components/Hero';
import Button from 'apollo-react/components/Button';
import './GreetingPanel.css';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { AuthenticatedTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../redux/slices/loaderSlice';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { login, logout } from '../../services/Authentication/SsoAuth';
import { FormLoginRequestObject } from '../../lib/utils/formLoginRequestObject';
import { useSearchParams } from 'react-router-dom';

const GreetingPanel = ({ fields }) => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [firstName, setUserFirstName] = useState('');
  const userName = useSelector((state) => state?.userSsoDetails?.userSsoData?.firstName) ?? '';
  const dispatch = useDispatch();
  const { sitecoreContext } = useSitecoreContext();
  const [searchParams] = useSearchParams();

  const getCurrentDate = () => {
    const currentDate = new Date();
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return currentDate.toLocaleDateString('en-US', options);
  };
  useEffect(() => {
    setUserFirstName(userName);
  }, [userName]);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isChildTransitionValue = searchParams?.get('childTransition');
      if (isChildTransitionValue && !isAuthenticated) {
        const loginRequest = FormLoginRequestObject({}, sitecoreContext);
        login(loginRequest, instance).catch((err) => console.error(err));
      }
    }
  }, []);

  return (
    <AuthenticatedTemplate>
      <Container maxWidth="lg" disableGutters>
        <Grid
          container
          className={'LandingPage'}
          sx={{ width: '100%' }}
          columnSpacing={{ md: 3 }}
          pl={{ xs: '16px', sm: '32px' }}
          pr={{ xs: '16px', sm: '32px' }}
        >
          <Grid item xs={10}>
            <Hero
              title={
                fields?.nameTitle?.value?.replace('[Name]', firstName) ??
                fields?.nameTitleDefault?.value
              }
              subtitle={getCurrentDate()}
              variant="inverse"
              style={{ paddingLeft: 'unset', background: 'unset' }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="primary"
              sx={{ borderRadius: '10px', backgroundColor: '#F2F3F4', color: '#307E9E' }}
              onClick={() => logout(instance)}
            >
              Logout
            </Button>
          </Grid>
        </Grid>
      </Container>
    </AuthenticatedTemplate>
  );
};

export default GreetingPanel;
