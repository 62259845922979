import React from 'react';
import { useSelector } from 'react-redux';
import ErrorBanner from './components/Banner/ErrorBanner';

const AppBanner = () => {
  const errorData = useSelector((state) => state.error);
  const { isShowDialog, message } = errorData;

  return <> {isShowDialog && <ErrorBanner errorMessage={message} />} </>;
};

export default AppBanner;
