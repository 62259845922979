import React, { useState } from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import Card from 'apollo-react/components/Card';
import CardContent from 'apollo-react/components/CardContent';
import Container from 'apollo-react/components/Container';
import idsTokens from 'apollo-react/utils/tokens/idsTokens';
import TextField from 'apollo-react/components/TextField';
import Button from 'apollo-react/components/Button';
import PasswordInput from 'apollo-react/components/PasswordInput';
import Checkbox from 'apollo-react/components/Checkbox';
import Switch from 'apollo-react/components/Switch';
import FormControlLabel from 'apollo-react/components/FormControlLabel';
import Box from 'apollo-react/components/Box';
import './LoginTile.css';

const LoginTile = ({ fields }) => {
  const [checkboxValue, setCheckboxValue] = useState(false);

  const handleCheckboxChange = (e, checked) => {
    setCheckboxValue(checked);
  };

  const [switchValue, setSwitchValue] = useState(false);

  const handleSwitchChange = (e, checked) => {
    setSwitchValue(checked);
  };

  return (
    <Container disableGutters sx={{ paddingRight: 0 }}>
      <Grid container className={'PreLogin'} sx={{ width: '100%', paddingLeft: 0 }} pt={'72px'}>
        <Grid
          item
          md={5}
          sx={{
            display: { xs: 'none', md: 'block' },
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          md={7}
          pr={{ xs: '24px', sm: '72px', md: '48px' }}
          pl={{ xs: '24px', sm: '72px', md: '24px' }}
          sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
        >
          <Container
            disableGutters
            sx={{
              maxWidth: {
                xs: '313px',
                sm: '552px',
                md: '467px',
                lg: '600px',
              },
            }}
          >
            <Image
              field={fields?.logoImage}
              style={{
                marginBottom: idsTokens.functional.spacing.content.spacious,
              }}
            />
            <Typography
              variant="subheadline"
              emphasis="high"
              paddingTop={'20px'}
              darkMode
              sx={{
                textAlign: 'left',
                paddingTop: idsTokens.functional.spacing.content.comfortable,
                paddingBottom: idsTokens.functional.spacing.content.comfortable,
                fontSize: { sm: '48px !important', md: '32px !important' },
              }}
            >
              {fields?.heading?.value}
            </Typography>
            <Typography
              variant="subtitle"
              darkMode
              sx={{
                textAlign: 'left',
                fontSize: { sm: '24px !important', md: '20px !important' },
              }}
            >
              {fields?.subheading?.value}
            </Typography>
            <Card
              sx={{
                marginTop: '24px',
                padding: idsTokens.functional.spacing.container.spacious,
                width: '100%',
              }}
            >
              <CardContent
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  paddingLeft: 'unset',
                  paddingRight: 'unset',
                }}
              >
                <Typography variant="title" emphasis="high">
                  {fields?.cardTitle?.value}
                </Typography>
                <TextField
                  label={fields?.userNameTxtbox?.value}
                  value="marta.clinical@iqvia.com" /* this value should be the user email entered in the pre-login page after being validated against backend */
                  fullWidth
                />
                <PasswordInput label={fields?.passwordTxtbox?.value} />
                <Checkbox
                  label={fields?.rememberUsernameText?.value}
                  checked={checkboxValue}
                  onChange={handleCheckboxChange}
                />
              </CardContent>
              <CardContent
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginRight: 'unset',
                  padding: 'unset !important',
                  gap: '16px',
                  paddingLeft: 'unset',
                  paddingRight: 'unset',
                }}
              >
                <Button variant="primary" href={fields?.loginBtn?.value?.href} fullWidth>
                  {fields?.loginBtn?.value?.text}
                </Button>
                <Button variant="text" href={fields?.forgotPassword?.value?.href} fullWidth>
                  {fields?.forgotPassword?.value?.text}
                </Button>
                <Box
                  sx={(theme) => ({
                    display: 'none',
                    justifyContent: 'space-between',
                    [theme.breakpoints.down('md')]: {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  })}
                >
                  <FormControlLabel
                    style={{ marginRight: 0 }}
                    control={
                      <Switch
                        sx={{ marginRight: 0 }}
                        size="small"
                        checked={switchValue}
                        onChange={handleSwitchChange}
                      />
                    }
                    label={
                      <Typography
                        variant="body1"
                        emphasis="low"
                        style={{
                          display: 'flex',
                          color: idsTokens.button.default.color.discreet.content.enabled,
                          gap: '8px',
                          justifyContent: 'center',
                          marginTop: '2px',
                        }}
                      >
                        {fields?.enableFaceIDText?.value}
                      </Typography>
                    }
                  />
                </Box>
              </CardContent>
            </Card>
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LoginTile;
