import axios from 'axios';
import { dispatch } from '../redux/store';
import { errorActions } from '../redux/slices/error';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 2000,
});

axios.interceptors.request.use(
  (config) => {
    // Modify the request configuration or add headers

    return config;
  },
  (error) => {
    // Handle request errors

    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    // Modify the response data or handle the response

    if (response?.status === 400 || response?.status === 401) {
      dispatch(errorActions.showError({ title: 'Error', message: 'message' }));
    }
    return response;
  },
  (error) => {
    console.log('error----' + JSON.stringify(error));
    // Handle response errors

    const { message } = error;
    dispatch(errorActions.showError({ title: 'Error', message: message }));
    return Promise.reject(error);
  }
);
