import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useEffect } from 'react';
import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
// import { blue } from '@mui/material/colors';
import { Grid } from '@mui/material';
import { login } from '../../services/Authentication/SsoAuth';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { FormLoginRequestObject } from '../../lib/utils/formLoginRequestObject';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: '20px',
  p: 4,
};

const Login = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { instance } = useMsal();
  const { sitecoreContext } = useSitecoreContext();

  useEffect(() => {
    handleOpen();
  }, []);

  const ssoLogin = async () => {
    try {
      const loginRequest = await FormLoginRequestObject({}, sitecoreContext);
      await login(loginRequest, instance);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <UnauthenticatedTemplate>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ backgroundColor: '#307E9E' }}
        >
          <Box sx={style}>
            <Typography
              variant="h4"
              sx={{
                textAlign: 'Left',
                fontSize: '30px',
                fontStyle: 'normal',
                lineHeight: '32px',
                alignSelf: 'center',
                fontWeight: '800',
              }}
            >
              Do you want to Login through SSO ?
            </Typography>
            <Typography
              variant="h6"
              sx={{
                textAlign: 'left',
                fontSize: '20px',
                fontStyle: 'normal',
                lineHeight: '32px',
                alignSelf: 'center',
                paddingTop: '20px',
              }}
            >
              There are many variations of passages of Lorem Ipsum available, but the maj ority have
              suffered alteration in som e form, by injected humour, or random suffered alteration
              in som e form, by injected humour, or random
            </Typography>
            <Grid container spacing={4} sx={{ paddingTop: '40px' }}>
              <Grid item xs={6} sx={{ paddingTop: '40px', alignSelf: 'center' }}>
                <Button
                  variant="outlined"
                  href="http://localhost:3000/sso-logout"
                  sx={{ borderRadius: '30px' }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      textAlign: 'center',
                      fontSize: '20px',
                      fontStyle: 'normal',
                      lineHeight: '32px',
                      alignSelf: 'center',
                      fontWeight: '800',
                    }}
                  >
                    Opt out of SSO
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  sx={{ borderRadius: '30px' }}
                  onClick={() => ssoLogin()}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      textAlign: 'center',
                      fontSize: '20px',
                      fontStyle: 'normal',
                      lineHeight: '32px',
                      alignSelf: 'center',
                      fontWeight: '800',
                    }}
                  >
                    Continue
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default Login;
