import gql from 'graphql-tag';

export const siteSettingsQuery = gql`
  query PhSiteSettings($siteSettingsId: String!, $language: String!, $rootpath: String!) {
    pageOne: search(
      where: {
        AND: [
          { name: "_path", value: $rootpath, operator: CONTAINS }
          { name: "_language", value: $language }
          { name: "_templates", value: $siteSettingsId }
        ]
      }
      first: 1
    ) {
      total
      pageInfo {
        endCursor
        hasNext
      }
      results {
        baseUrl: field(name: "baseUrl") {
          ... on TextField {
            value
          }
        }
        logoutUri: field(name: "logoutUri") {
          ... on LinkField {
            jsonValue
          }
        }
        signUpPolicy: field(name: "signUpPolicy") {
          ... on TextField {
            value
          }
        }
        signInPolicy: field(name: "signInPolicy") {
          ... on TextField {
            value
          }
        }
        redirectUri: field(name: "redirectUri") {
          ... on LinkField {
            jsonValue
          }
        }
        idleTimeOut: field(name: "idleTimeOut") {
          ... on TextField {
            value
          }
        }
        isFirstNamePrepopulate: field(name: "isFirstNamePrepopulate") {
          ... on CheckboxField {
            value
          }
        }
        isLastNamePrepopulate: field(name: "isLastNamePrepopulate") {
          ... on CheckboxField {
            value
          }
        }
        isSocialIdpEnabled: field(name: "isSocialIdpEnabled") {
          ... on CheckboxField {
            value
          }
        }
        socialIdpQueryParamValue: field(name: "socialIdpQueryParamValue") {
          ... on TextField {
            value
          }
        }
      }
    }
  }
`;

export const siteSettingsQueryParam = {
  siteSettingsId: '{7C0DDF5F-CDC3-4F76-A123-7A0570422928}',
  language: 'en',
  rootpath: '{F17DEF7A-749A-41C1-ADBB-AB75A8303A6B}',
};
