import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userSsoData: {},
};

const userSsoDetailsSlice = createSlice({
  name: 'userSsoDetailsSlice',
  initialState,
  reducers: {
    setSsoData: (state = initialState, action) => {
      return {
        ...state,
        userSsoData: action.payload,
      };
    },
    resetSsoData: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const { setSsoData, resetSsoData } = userSsoDetailsSlice.actions;
export default userSsoDetailsSlice.reducer;
