import React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import Card from 'apollo-react/components/Card';
import CardContent from 'apollo-react/components/CardContent';
import Container from 'apollo-react/components/Container';
import idsTokens from 'apollo-react/utils/tokens/idsTokens';
import TextField from 'apollo-react/components/TextField';
import Box from 'apollo-react/components/Box';
import Button from 'apollo-react/components/Button';
import CardActions from 'apollo-react/components/CardActions';

const ForgotUsernamePasswordTile = ({ fields }) => {
  const [error, setError] = React.useState(false);
  const [textfieldValue, setTextfieldValue] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isConfirmationPage, setIsConfirmationPage] = React.useState(false);

  const submitHandler = (event) => {
    event.preventDefault();
    debugger;

    if (isConfirmationPage) {
      return;
    }

    if (textfieldValue.trim().length === 0) {
      setError(true);
      setErrorMessage(fields?.requiredErrorMsg?.value);
      return;
    }

    /**
     * should this regex come from fields.regexForFormatCheck ?
     */
    const emailRegex = /^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$/;

    if (emailRegex.test(textfieldValue) === false) {
      setError(true);
      setErrorMessage(fields?.formatErrorMsg?.value);
      return;
    }

    /**
     * TODO
     * Add here code that communicates with the server and starts
     * the process of recovering the username or password
     */
    setError(false);
    setIsConfirmationPage(true);
  };

  const initialCardContent = (
    <>
      <Typography variant="title" component="h1" emphasis="high">
        {fields?.cardtitle?.value}
      </Typography>
      <Typography>{fields?.cardsubtitle?.value}</Typography>
      <TextField
        value={textfieldValue}
        label={fields?.textboxTitle?.value}
        placeholder="marta.clinical@iqvia.com"
        error={error}
        fullWidth
        helperText={error && errorMessage}
        onChange={(event) => {
          setError(false);
          setTextfieldValue(event.target.value);
        }}
      />
    </>
  );

  const initialCardActions = (
    <>
      <Button variant="primary" type="submit" fullWidth>
        {fields?.recoverBtn?.value?.text}
      </Button>
      {fields.showBackToLoginBtn.value && (
        <Button variant="text" href={fields?.backToLoginBtn?.value?.href} fullWidth>
          {fields?.backToLoginBtn?.value?.text}
        </Button>
      )}
    </>
  );

  const confirmationCardContent = (
    <>
      <Typography variant="title" emphasis="high">
        {fields?.confirmationTitle?.value}
      </Typography>
      <Typography>{fields?.confirmationSubTitle?.value}</Typography>
    </>
  );

  const confirmationCardActions = (
    <>
      <Button variant="primary" fullWidth href={fields?.backToLoginBtn?.value?.href}>
        {fields?.backToLoginBtn?.value?.text}
      </Button>
    </>
  );

  return (
    <Container disableGutters sx={{ paddingRight: 0 }}>
      <Grid
        container
        className={'ForgotUsernamePasswordTile'}
        sx={{ width: '100%', paddingLeft: 0 }}
        pt={'72px'}
      >
        <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}></Grid>
        <Grid
          item
          xs={12}
          md={7}
          pr={{ xs: '24px', sm: '72px', md: '48px' }}
          pl={{ xs: '24px', sm: '72px', md: '24px' }}
          sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
        >
          <Container
            disableGutters
            sx={{
              maxWidth: {
                xs: '313px',
                sm: '552px',
                md: '467px',
                lg: '600px',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: idsTokens.functional.spacing.content.comfortable,
              }}
            >
              <Image field={fields?.logoImage} />
              <Typography
                variant="headline"
                emphasis="high"
                darkMode
                component="p"
                sx={{
                  '&.MuiTypography-root': {
                    fontSize: '24px',
                    lineHeight: '36px', // idsTokens.typography.lineHeight.3x,
                    padding: 0,
                  },
                }}
              >
                {fields?.heading?.value}
              </Typography>
            </Box>
            <form method="post" onSubmit={submitHandler}>
              <Card
                sx={(theme) => ({
                  marginTop: '24px',
                  padding: idsTokens.functional.spacing.container.spacious,
                  [theme.breakpoints.down('sm')]: {
                    paddingLeft: idsTokens.functional.spacing.container.expanded,
                    paddingRight: idsTokens.functional.spacing.container.expanded,
                  },
                })}
              >
                <CardContent
                  sx={{
                    '&.MuiCardContent-root': {
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: 'unset',
                      paddingRight: 'unset',
                    },
                  }}
                >
                  {isConfirmationPage ? confirmationCardContent : initialCardContent}
                </CardContent>
                <CardActions
                  sx={{
                    flexDirection: 'column',
                    marginRight: 'unset',
                    padding: 'unset !important',
                  }}
                >
                  {isConfirmationPage ? confirmationCardActions : initialCardActions}
                </CardActions>
              </Card>
            </form>
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
};
export default ForgotUsernamePasswordTile;
