import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { logout } from '../../services/Authentication/SsoAuth';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const useAuthIdleTimer = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { sitecoreContext } = useSitecoreContext();
  const idleTimeout = (sitecoreContext?.siteSettings?.idleTimeOut?.value || 20) * 60 * 1000; // 12 seconds in milliseconds

  const handleIdleLogout = () => {
    logout(instance).catch((e) => {
      console.error('Logout failed: ', e);
    });
  };

  const { reset } = useIdleTimer({
    timeout: idleTimeout,
    onIdle: () => {
      if (isAuthenticated) {
        handleIdleLogout();
      }
    },
    debounce: 500,
    disabled: !isAuthenticated,
  });

  useEffect(() => {
    if (isAuthenticated) {
      reset();
    }
  }, [isAuthenticated, reset]);

  return { handleIdleLogout };
};

export default useAuthIdleTimer;
