import React, { lazy, Suspense, useEffect } from 'react';
import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import fastDeepEqual from 'fast-deep-equal/es6/react';
import Helmet from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.css';
import '../../assets/app.css';
import useSsoAuthentication from '../../lib/hooks/useSsoAuthentication';
import './Login.css';
import Box from 'apollo-react/components/Box';
import backgroundWavesLight from './backgroundWaves-light.svg';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import useAuthIdleTimer from '../../lib/hooks/useIdleTimer';

const Login = ({ route }) => {
  // const [isClient, setIsClient] = useState(false);
  useSsoAuthentication(route);
  useAuthIdleTimer();
  const Loader = lazy(() => import('../../components/shared/Loader/Loader'));
  const isAuthenticated = useIsAuthenticated();
  const sitecoreContext = useSitecoreContext();
  const navigate = useNavigate();
  // useEffect(() => {
  //   setIsClient(true);
  // }, []);

  useEffect(() => {
    if (isAuthenticated && !route?.fields?.isLandingPage?.value) {
      navigate(sitecoreContext?.sitecoreContext?.siteSettings?.redirectUri?.jsonValue?.value?.href);
    }
  }, []);

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <title>
          {(route.fields && route.fields.pageTitle && route.fields.pageTitle.value) || 'Page'}
        </title>
      </Helmet>
      <Suspense fallback={<div>Loading...</div>}>
        <Loader />
      </Suspense>
      <Box
        sx={(theme) => ({
          background: `url(${route.fields.backgroundImage?.value?.src}), url(${backgroundWavesLight}),linear-gradient(to bottom, #01828E, #01678E )`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top left, bottom right',
          backgroundSize: 'auto 40vw, auto, auto',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',

          [theme.breakpoints.down('md')]: {
            background: `url(${backgroundWavesLight}),linear-gradient(to bottom, #01828E, #01678E )`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom right',
          },
        })}
      >
        <div>
          <Placeholder name="jss-header" rendering={route} />
        </div>
        <Box
          className="container"
          sx={{
            paddingRight: 0,
            paddingLeft: 0,
            maxWidth: '100% !important',
          }}
        >
          <Placeholder name="jss-main" rendering={route} />
        </Box>
        <Box
          sx={{
            marginTop: 'auto',
          }}
        >
          <Placeholder name="jss-footer" rendering={route} />
        </Box>
      </Box>
    </React.Fragment>
  );
};

const propsAreEqual = (prevProps, nextProps) => fastDeepEqual(prevProps.route, nextProps.route);

export default React.memo(Login, propsAreEqual);
