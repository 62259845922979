import React, { useEffect } from 'react';
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import Card from 'apollo-react/components/Card';
import CardContent from 'apollo-react/components/CardContent';
import Container from 'apollo-react/components/Container';
import SegmentedControlGroup from 'apollo-react/components/SegmentedControlGroup';
import SegmentedControl from 'apollo-react/components/SegmentedControl';
import idsTokens from 'apollo-react/utils/tokens/idsTokens';
import './StudyCard.css';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProfileDetailsData } from '../../redux/slices/ProfileDetails';
import ArrowCircleRight from 'apollo-react/icons/ArrowCircleRight';
import Box from 'apollo-react/components/Box';
import { CardActionArea } from '@mui/material';
// import Button from 'apollo-react/components/Button';

const StudyCard = ({ fields }) => {
  const data = useSelector((state) => state.home.profileData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProfileDetailsData());
  }, []);

  return (
    <AuthenticatedTemplate>
      <Container maxWidth="lg" disableGutters>
        <Grid
          container
          className={'LandingPage'}
          sx={{ width: '100%' }}
          columnSpacing={{ md: 3 }}
          pl={{ xs: '16px', sm: '32px' }}
          pr={{ xs: '16px', sm: '32px' }}
          pb={'64px'}
        >
          <Grid item xs={12}>
            <Typography
              variant="title"
              emphasis="high"
              color={'#0041C2'}
              paddingTop={'20px'}
              darkMode
              sx={{
                textAlign: 'left',
              }}
            >
              <RichText field={fields?.sectionTitle} />
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} paddingTop={'8px'}>
            <SegmentedControlGroup
              value={'active'}
              exclusive
              onChange={(event, value) => setValue(value)}
              sx={{
                width: '100%',
                justifyContent: 'center',
                margin: '0 auto 16px auto',
              }}
            >
              <SegmentedControl value="active">{`${fields?.activeText?.value}`}</SegmentedControl>
              <SegmentedControl value="inactive">{`${fields?.inactiveText?.value}`}</SegmentedControl>
            </SegmentedControlGroup>
            {data?.studyDetails?.map((item, index) => (
              <Card key={`${index}0`} sx={{ marginBottom: '16px', padding: '16px', gap: '16px' }}>
                <CardContent style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="subtitle" emphasis="high">
                    {item.studyName}
                  </Typography>
                </CardContent>
                <CardContent
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '16px',
                    flexWrap: 'wrap',
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="bodySmall" emphasis="low">
                      {'Investigator Name'}
                    </Typography>
                    <Typography variant="bodyDefault" emphasis="high">
                      {item.investigatorName}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="bodySmall" emphasis="low">
                      {'Site Location'}
                    </Typography>
                    <Typography variant="bodyDefault" emphasis="high">
                      {item.siteLocation}
                    </Typography>
                  </Box>
                </CardContent>
                <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  {/* here goes the lab logo the delegate access tag (if exists) on the study card */}
                </CardContent>
              </Card>
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            borderColor={'#0000FF'}
            sx={{
              textAlign: 'left',
              fontSize: '20px',
              fontStyle: 'normal',
              lineHeight: '32px',
              paddingTop: '8px',
            }}
          >
            <CardActionArea
              href={fields?.trialSearchPanelLink?.value.href}
              sx={{
                '&:hover': {
                  textDecoration: 'none',
                },
              }}
            >
              <Card
                sx={(theme) => ({
                  height: '100%',
                  background:
                    idsTokens.functional.color.surface.container.transparent.default.pressed,
                  border: 'unset',
                  overflowX: 'clip',
                  ' & img:last-child': {
                    width: 'auto',
                    height: '100%',
                    margin: '0 auto',
                  },
                  [theme.breakpoints.down('md')]: {
                    minHeight: '400px',
                    maxHeight: '600px',
                    ' & img:last-child': {
                      width: '100%',
                      height: 'auto',
                    },
                  },
                })}
              >
                <CardContent sx={{ padding: 'unset' }}>
                  <Image
                    field={fields?.logoIcon}
                    height="32px"
                    style={{
                      paddingLeft: idsTokens.functional.spacing.action.expanded,
                      paddingRight: idsTokens.functional.spacing.action.expanded,
                      marginTop: `calc(${idsTokens.hero.brand.spacing.container.top} + 12px`,
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '8px',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: `${idsTokens.card.default.spacing.header.content.padding.top}
                      ${idsTokens.functional.spacing.action.expanded}
                      ${idsTokens.card.default.spacing.header.content.padding.bottom}
                      ${idsTokens.functional.spacing.action.expanded}
                      `,
                      gap: idsTokens.functional.spacing.action.expanded,
                    }}
                  >
                    <Typography
                      variant="subtitle"
                      color={'#fff'}
                      sx={{
                        textAlign: 'left',
                        color: '#fff !important',
                      }}
                    >
                      <RichText field={fields?.description} />
                    </Typography>
                    <ArrowCircleRight style={{ color: 'white', width: '32px', height: '32px' }} />
                  </Box>
                </CardContent>
                <CardContent
                  sx={{
                    padding: 'unset !important',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Image field={fields?.bannerImageLarge} width="100%" />
                </CardContent>
              </Card>
            </CardActionArea>
          </Grid>
        </Grid>
      </Container>
    </AuthenticatedTemplate>
  );
};

export default StudyCard;
