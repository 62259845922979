import { configureStore, combineReducers } from '@reduxjs/toolkit';
import ProfileDetailsPage from '../slices/ProfileDetails';
import AuthReducer from '../slices/Auth';
import ErrorReducer from '../slices/error';
import UserSsoReducer from '../slices/UserSsoDetails';
import loaderReducer from '../slices/loaderSlice';
import storage from 'redux-persist/lib/storage/session';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

export const rootReducer = combineReducers({
  home: ProfileDetailsPage,
  auth: AuthReducer,
  error: ErrorReducer,
  userSsoDetails: UserSsoReducer,
  loaderSlice: loaderReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  // these will be stored in sessionStorage
  whitelist: ['userSsoDetails'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV !== 'production',
});
export default store;
export const persistor = persistStore(store);
export const dispatch = store.dispatch;
export const getState = store.getState;
